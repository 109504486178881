import React, {useEffect} from 'react';
import Text from '../components/elements/Text';
import {signInWithPopup, GoogleAuthProvider, onAuthStateChanged} from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const navigate = useNavigate();

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate("/")
            }
        });
    }, [navigate])

    const onLogin = (e) => {
        e.preventDefault();
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                console.log(user, token);
                navigate("/")
            }).catch((error) => {
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.log(error, credential)
            });

    }

    return(
        <>
            <main >
                <section>
                    <div className="flex h-screen items-center justify-center px-4 sm:px-6 lg:px-8">
                        <div className="w-full max-w-md space-y-8">
                            <div>
                                <Text className="text-4xl text-white text-center font-bold mb-2">
                                    Zhelezyaka
                                </Text>
                            </div>

                            <form className="mt-8 space-y-6" >
                                <div>
                                    <button
                                        onClick={onLogin}
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Google
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Login
