import React, { useState, useEffect } from 'react';
import Card from '../components/elements/Card';
import Text from '../components/elements/Text';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom'

const Home = () => {
    const navigate = useNavigate();
    const [ accessToken, setAccessToken ] = useState("");

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log(user)
                setAccessToken(user.accessToken)
            } else {
                console.log("user is logged out")
                navigate("/login")
            }
        });
    }, [navigate])

    return (
        <section className="text-white pt-10 pb-20">

            <section className="grid grid-cols-1 gap-4">
                <Card className="text-center pb-16">
                    <Text className="font-semibold text-xl">
                        Access token:
                    </Text>

                    <Text className="text-sm pt-2">
                        {accessToken}
                    </Text>
                </Card>
            </section>

        </section>
    )
}

export default Home
