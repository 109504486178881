import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCl_Wv7w13QJtUlHl5kkGnKzNOHUge5rEY",
    authDomain: "memetalker-c09c1.firebaseapp.com",
    databaseURL: "https://memetalker-c09c1.firebaseio.com",
    projectId: "memetalker-c09c1",
    storageBucket: "memetalker-c09c1.appspot.com",
    messagingSenderId: "873672083228",
    appId: "1:873672083228:web:27095e6307f2415b855cd9"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
