import React from 'react'

const Card = ({children, className}) => {
    return (
        <div
            className={`bg-card px-6 py-10 rounded-xl ${className}`}
        >
            {children}
        </div>
    )
}

export default Card
