import React from 'react'

const Text = ({children, className}) => {
    return (
        <div
            className={`${className}`}
        >
            {children}
        </div>
    )
}

export default Text
